export const httpParamSerializer = (param: { [key: string]: any }): string =>
  Object.keys(param)
    .reduce<string[]>((acc, curr) => [...acc, param[curr] ? `${curr}=${param[curr]}` : ''], [])
    .join('&');

export const httpParamSerializerJQLike = (params: { [key: string]: any }): string =>
  Object.entries(params)
    .map(([key, value]) => {
      if (value === undefined || value === null || value === '') return;
      if (value && typeof value === 'object' && !Array.isArray(value)) {
        return Object.entries(value)
          .map(([nKey, nValue]) => `${key}[${nKey}]=${nValue}`)
          .join('&');
      }
      return `${key}=${value}`;
    })
    .filter(Boolean)
    .map((item) => (item ? encodeURI(item) : ''))
    .join('&');

export const parseURLSearchParams = (url: string = window.location?.search) => {
  const urlParams = new URLSearchParams(url);
  if (typeof urlParams?.keys !== 'function') return {};
  return Array.from(urlParams.keys()).reduce((acc: Record<string, any>, key) => {
    const value = urlParams.get(key);

    if (value) {
      switch (value) {
        case 'false': {
          acc[key] = false;
          break;
        }
        case 'true': {
          acc[key] = true;
          break;
        }
        case 'undefined': {
          acc[key] = undefined;
          break;
        }
        case 'null': {
          acc[key] = null;
          break;
        }
        default: {
          acc[key] = value;
        }
      }
    }

    return acc;
  }, {});
};

export const setURLSearchParam = (key: string, value: string): URL => {
  const url = new URL(window.location.toString());
  url.searchParams.set(key, value);
  return url;
};

export const deleteURLSearchParam = (key: string): URL => {
  const url = new URL(window.location.toString());
  url.searchParams.delete(key);
  return url;
};
