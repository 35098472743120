export const typography = {
  fontSizes: {
    xxs: 10,
    xs: 12,
    s: 14,
    m: 15,
    l: 18,
    xl: 20,
    xxl: 24,
    xxxl: 34,
  },
  fontFamily: {
    text: 'Montserrat',
    primary: 'Montserrat',
    secondary: 'Montserrat',
  },
  fontWeights: {
    light: 200,
    regular: 400,
    medium: 500,
    bold: 700,
    bolder: 800,
    black: 900,
  },
  textTransform: {
    uppercase: 'uppercase',
    lowercase: 'lowercase',
    capitalize: 'capitalize',
  },
};
