import { DiningOptionEnum, OrderFlowEnum, StoreItemValidityEnum } from '@goparrot/common';
import type { IDiningOptionInfo, IOrder, ISchedulingInfo, ISlotDetails } from '@goparrot/order-sdk';
import { getFulfillmentMinutes } from '@goparrot/order-sdk';
import type {
  DelayedOrdersDetails,
  DelayedOrdersDetailsDays,
  IDiningOptionConfig,
  IDiningOptionField,
  IDiningOptionSchema,
  IStoreFeaturesFlagsDto,
  OrderAheadAvailability,
  ReadStoreDto,
  ReceiveMethodEnum,
} from '@goparrot/store-v2-sdk';
import { DelayedOrdersUtils, DelayedOrdersValidationUtil, DiningOptionsUtil, HumanReadableUtils, LegacyDelayedOrdersUtils } from '@goparrot/store-v2-sdk';
import type { DiningOptionParams } from '@webstore-monorepo/shared/interfaces';
import { globalCache } from '@webstore-monorepo/shared/utils/global-cache';
import { NotificationBannerEnum } from '@webstore-monorepo/ui/notification-banner';
import type { RadioGroupItemType } from '@webstore-monorepo/ui/radio';
import groupBy from 'lodash/groupBy';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import * as Yup from 'yup';

import { diningOptionRelevantReceiveMethods, diningOptionsTestIds } from './constants';

export const ASAP_VALUE = 'ASAP';
export const MAX_ALLOCATION_PER_SLOT: number = 100;

export const ASAP_OPTION: DelayedOrdersDetailsDays = {
  formatted: ASAP_VALUE,
  value: ASAP_VALUE,
  humanReadable: ASAP_VALUE,
  date: ASAP_VALUE,
};

export type DiningOptionsParamsConfig = {
  [key in DiningOptionEnum]: DiningOptionParams;
};

export const sortByShowOrder = (dataArr: { [key: string]: any; showOrder?: number }[]) => orderBy(dataArr, 'showOrder');

export const preselectDiningOption = (
  diningOptionInfo: IDiningOptionInfo,
  diningOptionsParamsConfig: DiningOptionsParamsConfig,
  store: ReadStoreDto,
  isDynamicMenuEnabled?: boolean,
): DiningOptionEnum | undefined => {
  const defaultDiningOption = DiningOptionsUtil.getDefaultDiningOption(store);
  const diningOptionInfoFromCart = diningOptionInfo?.type;

  const isValid = (diningOption: DiningOptionEnum) => {
    const diningOptionParams = diningOptionsParamsConfig[diningOption];
    if (!diningOptionParams) return false;
    const { isDisabledASAP, isAvailable, isEnabled, isOrderAheadAvailable } = diningOptionParams;

    return isAvailable && isEnabled && (isOrderAheadAvailable || !isDisabledASAP);
  };

  const getValidDiningOption = (diningOptions: DiningOptionEnum[]) => {
    return diningOptions.find((option) => option && isValid(option));
  };

  if (globalCache.locationSelectorAddress) {
    const validDiningOption = getValidDiningOption([DiningOptionEnum.DELIVERY]);

    if (validDiningOption) {
      return validDiningOption;
    }
  }

  if (globalCache.locationSelectorIsPickup) {
    const validDiningOption = getValidDiningOption([DiningOptionEnum.TAKE_OUT, DiningOptionEnum.CURBSIDE]);

    if (validDiningOption) {
      return validDiningOption;
    }
  }

  if (defaultDiningOption) {
    const validDiningOption = getValidDiningOption([diningOptionInfoFromCart, defaultDiningOption]);

    if (validDiningOption) {
      return validDiningOption;
    }
  }

  if (diningOptionInfoFromCart && isValid(diningOptionInfoFromCart)) {
    return diningOptionInfoFromCart;
  }

  if (isDynamicMenuEnabled) {
    const diningOptionsParamsArr = Object.values(diningOptionsParamsConfig);
    const defaultPreselectedDiningOption = DiningOptionEnum.TAKE_OUT;
    const sortedDiningOptionsFromConfig = sortByShowOrder(diningOptionsParamsArr).map(({ value }) => value);
    const validDiningOption = getValidDiningOption([defaultPreselectedDiningOption, ...sortedDiningOptionsFromConfig]);

    if (validDiningOption) {
      return validDiningOption;
    }
  }

  const diningOptionKeys = Object.keys(diningOptionsParamsConfig) as DiningOptionEnum[];
  const validDiningOptionValuesForSelection = diningOptionKeys.filter(
    (diningOption: DiningOptionEnum) => isValid(diningOption) && diningOption !== DiningOptionEnum.DINE_IN,
  );

  if (validDiningOptionValuesForSelection.length === 1) {
    const validDiningOption = getValidDiningOption([validDiningOptionValuesForSelection[0]]);

    if (validDiningOption) {
      return validDiningOption;
    }
  }
};

export const generateFields = (schema: IDiningOptionSchema) => {
  const { required: requiredFields, properties, propertiesGroups } = schema;
  const noGroupLabel = 'no-group';

  const fields =
    properties &&
    Object.keys(properties).map((key: string) => {
      const fieldSchemaConfig: Record<'propertiesGroupId' | 'name' | 'label' | 'isRequired' | 'component' | 'placeholder' | 'minLength' | 'maxLength', any> =
        properties[key];
      const isRequired = requiredFields?.includes(key);
      const { propertiesGroupId = noGroupLabel } = fieldSchemaConfig;

      return { ...fieldSchemaConfig, name: key, isRequired, propertiesGroupId };
    });

  const getFieldLabel = (field: Record<string, any>) => (propertiesGroups && propertiesGroups[field?.propertiesGroupId]?.label) ?? field?.propertiesGroupId;

  return { groupedFields: groupBy(fields, getFieldLabel), noGroupLabel };
};

export const prepareFormikParams = (fields: any[]) => {
  const initialValues: Record<string, any> = {};
  const validationShape: any = {};
  const validationsByType: any = {
    string: Yup.string(),
    number: Yup.number(),
    default: Yup.string(),
  };

  fields.forEach((field) => {
    const { type, name, isRequired, label } = field;

    validationShape[name] = validationsByType[type] ?? validationsByType.default;

    if (isRequired) {
      validationShape[name] = validationShape[name]?.required(`${label || name} is required`);
    }

    initialValues[name] = '';
  });

  const validationSchema = Yup.object().shape(validationShape);

  return { initialValues, validationSchema };
};

export function getDateAndTimeFrames(store: ReadStoreDto, diningOptionParams: DiningOptionParams): DelayedOrdersDetails | null {
  if (!diningOptionParams) return null;
  const { isDisabledASAP, value: diningOption } = diningOptionParams;
  const receiveMethod = getDiningOptionRelevantReceiveMethod(diningOption);
  const { isOpen, isDeliveryAvailable } = HumanReadableUtils.getHumanReadableDataFromStore(store);

  if (!receiveMethod) return null;

  const availableDays = DelayedOrdersUtils.getAllowedDays(store, receiveMethod);

  const { isAvailable, days = [] } = availableDays;

  if (isDisabledASAP) {
    return {
      isAvailable,
      days: [...days],
    };
  } else {
    const isASAPAvailable: boolean = !!(isDeliveryRequired(diningOption) ? isDeliveryAvailable : isOpen);

    if (isASAPAvailable) {
      return {
        isAvailable,
        days: [ASAP_OPTION, ...days],
      };
    }
    return {
      isAvailable,
      days: [...days],
    };
  }
}
export const findLowestOrderEnabledValue = (obj?: IDiningOptionConfig): [DiningOptionEnum | undefined, IDiningOptionField | undefined] => {
  let lowestOrder = Infinity;
  let lowestOrderEnabledValue: IDiningOptionField | undefined = undefined;
  let lowestKey: DiningOptionEnum | undefined = undefined;

  for (const key in obj) {
    const value = obj[key as keyof IDiningOptionConfig];

    if (value && value.isEnabled && value.isDefault && value.showOrder && value.showOrder < lowestOrder) {
      lowestOrder = value.showOrder;
      lowestKey = key as keyof IDiningOptionConfig;
      lowestOrderEnabledValue = obj[lowestKey];
    }
  }
  return [lowestKey, lowestOrderEnabledValue];
};
/**
 * This is horrible and should not exist.
 * The dininigOptionInfo in the cart can have a default state that might not correspond with the enabled diningOptionsConfig in the store. Most often the default state is TAKE_OUT as it's the simplest one to setup
 * In this function we need to check if the default state is enabled and has some validation to check if the user can continue to checkout.
 * @param cart
 * @param store
 */
export const getInvalidMessageForDefaultDiningOption = (
  cart: IOrder,
  store: ReadStoreDto,
): {
  actualDiningOption: DiningOptionEnum | null;
  showError: boolean;
  variant?: NotificationBannerEnum;
  problemSource?: 'menu' | 'diningOption';
  delayedInfoText?: string;
  diningOptionText?: string;
  notificationBannerButtonText?: string;
  notificationBannerText?: string;
} => {
  if (cart.flow === OrderFlowEnum.DIGITAL) {
    return {
      showError: false,
      actualDiningOption: null,
    };
  }
  const [defaultDiningOptionInfoType, diningOptionMethod] = findLowestOrderEnabledValue(store.diningOptionsConfig);
  const cartDiningOptionMethod = store.diningOptionsConfig?.[cart.diningOptionInfo.type];
  const selectedDiningOptionInfo = cartDiningOptionMethod?.isEnabled ? cart.diningOptionInfo?.type : defaultDiningOptionInfoType;
  const selectedDiningOptionMethod = selectedDiningOptionInfo ? store.diningOptionsConfig?.[selectedDiningOptionInfo] : diningOptionMethod;

  if (cart.validity === StoreItemValidityEnum.SELECTED_TIME_SLOT_UNAVAILABLE && selectedDiningOptionInfo) {
    return {
      actualDiningOption: selectedDiningOptionInfo,
      showError: true,
      problemSource: 'diningOption',
      variant: NotificationBannerEnum.error,
      notificationBannerButtonText: 'Set time',
      delayedInfoText: 'Set time',
      notificationBannerText: `Seems that your previously selected ${DiningOptionsUtil.getHumanReadableName(
        selectedDiningOptionInfo,
        store,
      )} Time is not available anymore, please select new time`,
    };
  }

  if (!selectedDiningOptionInfo) {
    return {
      actualDiningOption: null,
      showError: true,
    };
  }

  const requiredFields = selectedDiningOptionMethod?.schema.required ?? [];
  const missingRequiredValues = requiredFields
    .map((key) => [key, !cart.diningOptionInfo.details[key]])
    .filter(([, showError]) => showError)
    .shift();

  if (missingRequiredValues && missingRequiredValues?.length > 0) {
    return {
      actualDiningOption: selectedDiningOptionInfo,
      problemSource: 'diningOption',
      showError: missingRequiredValues?.length > 0,
      variant: NotificationBannerEnum.error,
      diningOptionText: `Set details`,
      notificationBannerButtonText: `Set details`,
      notificationBannerText: `Please specify your ${DiningOptionsUtil.getHumanReadableName(selectedDiningOptionInfo, store)} info`,
    };
  }

  if (selectedDiningOptionInfo === DiningOptionEnum.DELIVERY) {
    const noAddress = !cart.diningOptionInfo.details.address;
    return {
      actualDiningOption: selectedDiningOptionInfo,
      problemSource: 'diningOption',
      variant: NotificationBannerEnum.error,
      showError: noAddress,
      notificationBannerButtonText: noAddress ? 'Set address' : undefined,
      diningOptionText: noAddress ? 'Set address' : undefined,
      notificationBannerText: 'Please specify your delivery address',
    };
  }

  return { actualDiningOption: selectedDiningOptionInfo, variant: NotificationBannerEnum.error, showError: false };
};

export const isASAPValue = (value: string) => value === ASAP_VALUE;

export const isDiningOptionAvailable = (diningOption: DiningOptionEnum, availableDiningOptions: DiningOptionEnum[]) =>
  availableDiningOptions.includes(diningOption);

export const getDiningOptionRelevantReceiveMethod = (diningOption: DiningOptionEnum): ReceiveMethodEnum => {
  return diningOptionRelevantReceiveMethods[diningOption];
};

export const isGreaterThenMinimumOrder = (minimum: number, value: number) => {
  return value >= minimum;
};

export const isLessThenMaximumOrder = (maximum: number, value: number) => {
  return value <= maximum;
};

export const isDeliveryRequired = (diningOption: DiningOptionEnum) => {
  return [DiningOptionEnum.DELIVERY, DiningOptionEnum.TO_COUNTER].includes(diningOption);
};

export const isOrderSubtotalValid = (diningOptionParams: DiningOptionParams, total: number): boolean => {
  const { minimumOrder, maximumOrder } = diningOptionParams;

  return isGreaterThenMinimumOrder(minimumOrder, total) && (!maximumOrder || isLessThenMaximumOrder(maximumOrder, total));
};

export const isDiningOptionValid = (diningOptionParams: DiningOptionParams, total: number, validateTotal: boolean) => {
  const { isEnabled, isOrderAheadAvailable, isAllowed } = diningOptionParams;

  return (
    isEnabled && ((validateTotal && isOrderSubtotalValid(diningOptionParams, total)) || !validateTotal) && (isAllowed || (!isAllowed && isOrderAheadAvailable))
  );
};

const isASAPBusinessHoursAllowed = (isAsapOrderingAfterBusinessHoursDisabled: boolean, isPrepTimeInWorkingHours: boolean) => {
  return !(isAsapOrderingAfterBusinessHoursDisabled && !isPrepTimeInWorkingHours);
};

const getOrderPrepMinutes = (cart: IOrder, store: ReadStoreDto, diningOptionParams: DiningOptionParams) => {
  const { preparedIn, fulfilledIn } = getFulfillmentMinutes(cart?.schedulingInfo, {
    pickupTimeDelta: store.pickup?.time,
    deliveryTimeDelta: store.delivery?.time,
  });

  return isDeliveryRequired(diningOptionParams?.value) ? fulfilledIn : preparedIn;
};
const isPrepTimeInWorkingHours = (cart: IOrder, store: ReadStoreDto, diningOptionParams: DiningOptionParams): boolean => {
  const receiveMethod = getDiningOptionRelevantReceiveMethod(diningOptionParams.value);
  const schedule = DelayedOrdersUtils.getScheduleByReceiveMethod(receiveMethod, store);
  const prepMinutes = getOrderPrepMinutes(cart, store, diningOptionParams);
  const startingDate = new Date(Date.now() + prepMinutes * 1000 * 60 - 5000); // fulfillment time - 5 sec
  const endingDate = new Date(Date.now() + prepMinutes * 1000 * 60 + 5000); // fulfillment time + 5 sec

  return LegacyDelayedOrdersUtils.haveFramesForInterval(schedule, store.contactInfo.timeZone?.id, startingDate, endingDate);
};

export const checkASAPSlotAvailability = (slots: ISlotDetails[], cart: IOrder, store: ReadStoreDto, diningOptionParams: DiningOptionParams): boolean => {
  const { isAsapOrderingAfterBusinessHoursDisabled, isOrderThrottlingEnabled } = store.featuresFlags as IStoreFeaturesFlagsDto;
  const isASAPAllowed = isASAPBusinessHoursAllowed(!!isAsapOrderingAfterBusinessHoursDisabled, isPrepTimeInWorkingHours(cart, store, diningOptionParams));

  if (!isASAPAllowed) {
    return false;
  }

  if (!isOrderThrottlingEnabled) {
    return true;
  }

  const prepMinutes = getOrderPrepMinutes(cart, store, diningOptionParams);
  const { delayStepSize: pickupDelayStepSize } = store.pickup?.delayedOrders ?? {};
  const { delayStepSize: deliveryDelayStepSize } = store.delivery?.delayedOrders ?? {};

  const stepSize = (isDeliveryRequired(diningOptionParams?.value) ? deliveryDelayStepSize : pickupDelayStepSize) ?? 0;
  const slotNumber = Math.floor(prepMinutes / stepSize);
  const asapSlot = slots[slotNumber];

  return asapSlot ? asapSlot.allocation < MAX_ALLOCATION_PER_SLOT : true;
};

export const getInvalidMessage = (diningOptionParams: DiningOptionParams, diningOption: DiningOptionEnum, total: number, store: ReadStoreDto) => {
  const { minimumOrder, maximumOrder, messages, isAllowed, isOrderAheadAvailable } = diningOptionParams;
  const { deliveryTime } = HumanReadableUtils.getHumanReadableDataFromStore(store);

  const receiveMethodNotAvailable = !isAllowed && !isOrderAheadAvailable;

  if (!isGreaterThenMinimumOrder(minimumOrder, total) && !receiveMethodNotAvailable) {
    return messages.minimumOrder;
  }

  if (maximumOrder && !isLessThenMaximumOrder(maximumOrder, total) && !receiveMethodNotAvailable) {
    return messages.maximumOrder;
  }

  const isPickupOrHasDeliveryAvailableTime = !isDeliveryRequired(diningOption) || deliveryTime;

  if (receiveMethodNotAvailable && isPickupOrHasDeliveryAvailableTime) {
    return messages.willBeAvailable;
  }
};

export const getRadioGroupData = (
  diningOptionsParamsConfig: DiningOptionsParamsConfig,
  store: ReadStoreDto,
  isDynamicMenuEnabled: boolean,
  cartValueToCompare?: number,
  validateTotal: boolean = true,
) => {
  const data = Object.keys(diningOptionsParamsConfig).map((diningOption) => {
    const diningOptionParams = diningOptionsParamsConfig[diningOption as DiningOptionEnum];

    if (!diningOptionParams.isEnabled || !diningOptionParams.isAvailable || diningOptionParams.isHidden) {
      return null;
    }

    const diningOptionSharedData: RadioGroupItemType & { showOrder: number } = {
      value: diningOption,
      showOrder: diningOptionParams.showOrder ?? 0,
      label: diningOptionParams.name,
    };

    diningOptionSharedData.disabled = isDynamicMenuEnabled
      ? diningOptionParams.isDisabledASAP && !diningOptionParams.isOrderAheadAvailable
      : !isDiningOptionValid(diningOptionParams, cartValueToCompare ?? 0, validateTotal);

    if (!isDynamicMenuEnabled && validateTotal) {
      diningOptionSharedData.helperText = getInvalidMessage(diningOptionParams, diningOption as DiningOptionEnum, cartValueToCompare ?? 0, store);
    }

    return diningOptionSharedData;
  });

  return orderBy(data, 'showOrder')
    .filter((el) => el !== null)
    .map((el) => omit(el, ['showOrder'])) as RadioGroupItemType[];
};

export const generateDiningOptionsParamsConfig = (
  diningOptionsConfig: IDiningOptionConfig,
  availableDiningOptions: DiningOptionEnum[],
  store: ReadStoreDto,
  orderAheadAvailabilityForMethods: OrderAheadAvailability,
  schedulingInfo: ISchedulingInfo,
): DiningOptionsParamsConfig => {
  const { preparedIn: pickupTime, fulfilledIn: deliveryTime } = getFulfillmentMinutes(schedulingInfo, {
    pickupTimeDelta: store.pickup?.time,
    deliveryTimeDelta: store.delivery?.time,
  });
  const { pickup, delivery } = store;
  const { minOrderTotal: pickupMinimumOrder, maxOrderTotal: pickupMaximumOrder } = pickup ?? {};
  const { minOrderTotal: minimumOrder, maxOrderTotal: maximumOrder } = delivery ?? {};
  const { openingTime, isOpen, isDeliveryAvailable, deliveryTime: deliveryAvailableTime } = HumanReadableUtils.getHumanReadableDataFromStore(store);
  const config: DiningOptionsParamsConfig = {} as DiningOptionsParamsConfig;

  Object.keys(diningOptionsConfig).forEach((diningOption) => {
    const diningOptionSettings = diningOptionsConfig[diningOption as DiningOptionEnum];
    const { name, infoMessage, isEnabled, showOrder, isDefault } = diningOptionSettings ?? {};
    const receiveMethod = getDiningOptionRelevantReceiveMethod(diningOption as DiningOptionEnum);

    const diningOptionData = {
      isEnabled,
      isAvailable: isDiningOptionAvailable(diningOption as DiningOptionEnum, availableDiningOptions),
      isDefault,
      isHidden: false,
      minimumOrder: isDeliveryRequired(diningOption as DiningOptionEnum) ? minimumOrder : pickupMinimumOrder,
      maximumOrder: isDeliveryRequired(diningOption as DiningOptionEnum) ? maximumOrder : pickupMaximumOrder,
      showOrder,
      value: diningOption,
      name: name || DiningOptionsUtil.getHumanReadableName(diningOption as DiningOptionEnum),
      isDisabledASAP: !DelayedOrdersValidationUtil.canPlaceDelayedOrder(store, { receiveMethod, isASAP: true, flow: OrderFlowEnum.REGULAR }).isOrderingAllowed,
      isOrderAheadAvailable: orderAheadAvailabilityForMethods[receiveMethod],
      testId: diningOptionsTestIds[diningOption as DiningOptionEnum],
    };

    if (diningOption === DiningOptionEnum.TAKE_OUT) {
      // @ts-ignore
      config[diningOption] = {
        ...diningOptionData,
        isAllowed: isOpen,
        messages: {
          minimumOrder: `(Pickup minimum is: $${pickupMinimumOrder})`,
          maximumOrder: `(Pickup maximum is: $${pickupMaximumOrder})`,
          willBeAvailable: `(Will be available ${openingTime === 'never' ? 'soon' : openingTime})`,
          infoMessage: infoMessage && infoMessage.replace('%pickupTime%', pickupTime?.toString()),
          delayedTimeError: 'order.delayed.error.takeOut',
        },
        notifications: {
          minimumOrder: `Pickup minimum is: $${pickupMinimumOrder}`,
          maximumOrder: `Pickup maximum is: $${pickupMaximumOrder}`,
          willBeAvailable: `Pickup will be available ${openingTime === 'never' ? 'soon' : openingTime}`,
        },
      };
    }

    if (diningOption === DiningOptionEnum.DELIVERY) {
      // @ts-ignore
      config[diningOption] = {
        ...diningOptionData,
        isAllowed: isDeliveryAvailable,
        messages: {
          minimumOrder: `(Delivery minimum is: $${minimumOrder})`,
          maximumOrder: `(Delivery maximum is: $${maximumOrder})`,
          willBeAvailable: `(Will be available ${deliveryAvailableTime === 'never' ? 'soon' : deliveryAvailableTime})`,
          infoMessage: infoMessage && infoMessage.replace('%deliveryTime%', deliveryTime?.toString()),
          delayedTimeError: 'order.delayed.error.delivery',
        },
        notifications: {
          minimumOrder: `Delivery minimum is: $${minimumOrder}`,
          maximumOrder: `Delivery maximum is: $${maximumOrder}`,
          willBeAvailable: `Delivery will be available ${deliveryAvailableTime === 'never' ? 'soon' : deliveryAvailableTime}`,
        },
      };
    }

    if (diningOption === DiningOptionEnum.CURBSIDE) {
      // @ts-ignore
      config[diningOption] = {
        ...diningOptionData,
        isAllowed: isOpen,
        messages: {
          minimumOrder: `(Curbside pickup minimum is: $${pickupMinimumOrder})`,
          maximumOrder: `(Curbside pickup maximum is: $${pickupMaximumOrder})`,
          willBeAvailable: `(Will be available ${openingTime === 'never' ? 'soon' : openingTime})`,
          infoMessage: infoMessage && infoMessage.replace('%pickupTime%', deliveryTime?.toString()),
          delayedTimeError: 'order.delayed.error.curbside',
        },
        notifications: {
          minimumOrder: `Curbside pickup minimum is: $${pickupMinimumOrder}`,
          maximumOrder: `Curbside pickup maximum is: $${pickupMaximumOrder}`,
          willBeAvailable: `Curbside pickup will be available ${openingTime === 'never' ? 'soon' : openingTime}`,
        },
      };
    }

    if (diningOption === DiningOptionEnum.DINE_IN) {
      // @ts-ignore
      config[diningOption] = {
        ...diningOptionData,
        isHidden: true,
        isAllowed: isOpen,
        messages: {
          minimumOrder: `(Minimum is: $${pickupMinimumOrder})`,
          maximumOrder: `(Maximum is: $${pickupMaximumOrder})`,
          willBeAvailable: `(Will be available ${openingTime === 'never' ? 'soon' : openingTime})`,
          infoMessage: infoMessage && infoMessage.replace('%pickupTime%', deliveryTime?.toString()),
          delayedTimeError: 'order.delayed.error.takeOut',
        },
        notifications: {
          minimumOrder: `Minimum is: $${pickupMinimumOrder}`,
          maximumOrder: `Maximum is: $${pickupMaximumOrder}`,
          willBeAvailable: `Will be available ${openingTime === 'never' ? 'soon' : openingTime}`,
        },
      };
    }
  });

  return config;
};
