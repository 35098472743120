import type { DiningOptionEnum } from '@goparrot/common';
import { OrderDelayedInfoUtil } from '@goparrot/order-sdk';
import type {
  ICategory,
  IMenuConcept,
  INestedStoreItemGroup,
  IStoreItemBundle,
  IStoreItemCombo,
  IStoreItemWithOptions,
  IWebstoreItems,
  TimedMenuWebstoreItemType,
  WebstoreItemType,
} from '@goparrot/storeitems-sdk';
import type { ReadTopItemDto } from '@goparrot/top-item-client-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import type { MenuCategories } from '@webstore-monorepo/shared/interfaces';
import { filterItemsByCategoryAndSort, filterTopSellingItems } from '@webstore-monorepo/shared/utils/cart';
import { httpParamSerializerJQLike } from '@webstore-monorepo/shared/utils/url';
import { getWaterMark } from '@webstore-monorepo/shared/utils/watermark';
import type { AxiosError } from 'axios';
import orderBy from 'lodash/orderBy';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { QueryKeysEnum } from './keys';

export interface WebstoreMenuItems {
  categories: ICategory[];
  storeItems: WebstoreItemType[] | TimedMenuWebstoreItemType[];
  // @todo for Slava -> rename from `storeItemsByCategory` to `storeItemsByCategories`
  storeItemsByCategory: MenuCategories[];
  storeItemBundles: IStoreItemBundle[];
  combos: IStoreItemCombo[];
}

export const useGetMenuConcepts = (options?: UseQueryOptions<IMenuConcept[], AxiosError, IMenuConcept[], any>): UseQueryResult<IMenuConcept[]> => {
  const { storeId } = useWebStore();
  return useQuery(
    QueryKeysEnum.getMenuConcepts,
    async (): Promise<IMenuConcept[]> => {
      const menuConcepts: IMenuConcept[] = await axios.get(`/api/v2/stores/${storeId}/menu-concepts`);
      return orderBy(
        menuConcepts.filter(({ isEnabled }) => isEnabled),
        'showOrder',
      );
    },
    { staleTime: Infinity, ...options },
  );
};

export const useWebStoreItems = (
  props?: {
    selectedMenuConceptUniqueNames?: string[];
  },
  options?: UseQueryOptions<WebstoreMenuItems, AxiosError, WebstoreMenuItems, any>,
): UseQueryResult<WebstoreMenuItems> => {
  const { selectedMenuConceptUniqueNames } = props ?? {};
  const webstore = useWebStore();
  const soldOutWatermark = getWaterMark(webstore.metadata?.menu?.soldOutWatermark);
  const cart = useCartState();
  const showSoldOutItems = webstore.metadata?.menu?.showSoldOutItems;
  const fulfilledAt = !OrderDelayedInfoUtil.isASAP(cart) && cart?.delayedInfo?.date ? cart?.delayedInfo?.date : '';
  const diningOptions = [cart.diningOptionInfo.type];
  const { merchantId, storeId } = webstore;

  const getWebstoreItems = async (
    fulfillmentDate?: string | Date,
    showSoldOutItems?: { storeItems?: boolean },
    selectedDiningOptions?: DiningOptionEnum[],
    selectedMenuConceptUniqueNames?: string[],
    soldOutWatermark?: string,
  ): Promise<WebstoreMenuItems> => {
    const { storeItems, categories, storeItemBundles, combos }: IWebstoreItems = await axios.get(
      `/api/v2/merchants/${merchantId}/stores/${storeId}/store-items`,
      {
        params: { showSoldOutItems, fulfillmentDate, selectedMenuConceptUniqueNames, selectedDiningOptions },
        paramsSerializer: httpParamSerializerJQLike,
      },
    );
    const storeItemsByCategory = filterItemsByCategoryAndSort(storeItems, categories, soldOutWatermark);

    return { storeItems, categories, storeItemsByCategory, storeItemBundles, combos };
  };

  return useQuery(
    [QueryKeysEnum.storeItems, showSoldOutItems, selectedMenuConceptUniqueNames, fulfilledAt, cart.diningOptionInfo.type],
    async () => await getWebstoreItems(fulfilledAt, showSoldOutItems, diningOptions, selectedMenuConceptUniqueNames, soldOutWatermark),
    {
      enabled: !!cart,
      staleTime: Infinity,
      cacheTime: 0,
      ...options,
    },
  );
};

export const useTopSellingItems = (
  options?: UseQueryOptions<(IStoreItemWithOptions | INestedStoreItemGroup)[], AxiosError, (IStoreItemWithOptions | INestedStoreItemGroup)[], any>,
): UseQueryResult<(IStoreItemWithOptions | INestedStoreItemGroup)[]> => {
  const { data } = useWebStoreItems();
  const { merchantId, storeId } = useWebStore();

  const getTopSellingItems = async (storeItemsByCategory?: MenuCategories[]): Promise<(IStoreItemWithOptions | INestedStoreItemGroup)[]> => {
    const topSellingItems: ReadTopItemDto[] = await axios.get(`/api/v2/merchants/${merchantId}/stores/${storeId}/top-items`, {});

    return filterTopSellingItems(topSellingItems, storeItemsByCategory) as (IStoreItemWithOptions | INestedStoreItemGroup)[];
  };
  return useQuery(QueryKeysEnum.topSellingItems, async () => await getTopSellingItems(data?.storeItemsByCategory), {
    enabled: (data?.storeItemsByCategory?.length ?? 0) > 0,
    staleTime: 5000,
    ...options,
  });
};

export const useGetCategoryNavbarItems = (selectedMenuConceptUniqueNames?: string[]): ICategory[] => {
  const { data } = useWebStoreItems({ selectedMenuConceptUniqueNames });

  const { data: topSellingItems } = useTopSellingItems();
  const webstore = useWebStore();
  // @ts-ignore
  const { topSellingItemsTitle, topSellingCategoryUuid } = webstore.metadata;

  let topSellingItemsCategory: ICategory | null = null;
  if ((topSellingItems?.length ?? 0) >= 4 && topSellingCategoryUuid && topSellingItemsTitle) {
    topSellingItemsCategory = {
      uniqueName: topSellingCategoryUuid ?? '',
      title: topSellingItemsTitle ?? '',
    } as ICategory;
  }
  return data?.storeItemsByCategory.length
    ? topSellingItemsCategory
      ? [topSellingItemsCategory, ...data.storeItemsByCategory]
      : data?.storeItemsByCategory
    : [];
};
